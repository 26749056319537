(function($) {
    var servicesIsHidden = true;

    $(document).on(
        'click',
        '.header-service-container span,.header-service-container img',
        function(e) {
            if ($('.header-service-container').hasClass('active')) {
                $('.header-service-container').removeClass('active');
                servicesIsHidden = true;
            } else {
                $('.header-service-container').addClass('active');
                servicesIsHidden = false;
            }
        }
    );

    $(document).on(
        {
            mouseleave: function() {
                if (!(window.is_touch_device() && window.detectIE())) {
                    $(this).removeClass('active');

                    servicesIsHidden = true;
                }
            }
        },
        '.header-service-container'
    );

    $(document).on('touchmove', function() {
        if (!servicesIsHidden)
            setTimeout(function() {
                $('.header-service-container').removeClass('active');
                servicesIsHidden = true;
            }, 0);
    });

    $(document).on(
        'click',
        'ul#menu-main-menu > li > .sub-menu-container > .sub-menu > li > a',
        function(e) {
            if ($('#menu [data-test="responsive"]').css('opacity') == '1')
                // is mobile
                e.preventDefault();
        }
    );

    $('[data-out="mobile-menu-sub"][data-depth="1"]').each(function() {
        var left = $(
            '[data-out="mobile-menu-sub"][data-depth="2"]:first'
        ).offset().left;

        $(this).prepend(
            '<span data-out="menu-arrow-right" style="left: ' +
                left +
                'px;"></span>'
        );
    });

    var $arrows = $('[data-out="menu-arrow-right"]');

    $(document).ready(function() {
        var left = $(
            '[data-out="mobile-menu-sub"][data-depth="2"]:first'
        ).offset().left;
        $arrows.css('left', left + 'px');
    });

    function bla(elem) {
        var $elem = $(elem);

        $(
            'ul#menu-main-menu > li > .sub-menu-container > .sub-menu > li'
        ).removeClass('mouseover');
        $elem
            .parent()
            .parent()
            .addClass('mouseover');

        $(
            'ul#menu-main-menu > li > .sub-menu-container > .sub-menu > li > a > span'
        ).removeClass('mouseover');
        $elem.addClass('mouseover');

        var top =
            $(elem).offset().top -
            $('[data-out="mobile-menu-sub"][data-depth="1"]:first').offset()
                .top;

        $arrows.css('top', top + 'px');
    }

    var timeout_arrow = false;

    $(window).resize(function() {
        if (timeout_arrow) clearTimeout(timeout_arrow);

        timeout_arrow = setTimeout(function() {
            var left = $(
                '[data-out="mobile-menu-sub"][data-depth="2"]:first'
            ).offset().left;
            $arrows.css('left', left + 'px');
        }, 500);
    });

    var _blockSecondMouseEnter = false; // fix for IE on MS Surface

    $(document).on(
        {
            mouseenter: function() // muss immer als zweites feuern kommen
            {
                var that = this;

                bla(that);

                $('ul#menu-main-menu .is-open').removeClass('is-open');
                $(that)
                    .parent()
                    .parent()
                    .find('.sub-menu-container')
                    .addClass('is-open');

                _blockSecondMouseEnter = true; // fix for IE on MS Surface
            }
        },
        'ul#menu-main-menu > li > .sub-menu-container > .sub-menu > li > a > span'
    );

    $(document).on(
        {
            mouseenter: function() {
                if (window.is_touch_device() && window.detectIE()) {
                    // fix for IE on MS Surface
                    if (_blockSecondMouseEnter) {
                        _blockSecondMouseEnter = false;
                        return;
                    }

                    $('.touched').removeClass('touched');
                }

                $('ul#menu-main-menu .is-open').removeClass('is-open');

                var elem = $(this)
                    .find(
                        ' > [data-out="mobile-menu-sub"] > .sub-menu > li:not([data-out="mobile-menu-breadcrumb"]):first > a > span'
                    )
                    .get(0);

                bla(elem);
            }
        },
        'ul#menu-main-menu > li'
    );

    var menuDelay = setTimeout(function() {}, 0);
    var menuDelay2 = setTimeout(function() {}, 0);

    $(document).on(
        {
            mouseenter: function() {
                var that = this;

                clearTimeout(menuDelay);
                clearTimeout(menuDelay2);

                setTimeout(function() {
                    $('#header').addClass('mouseout');
                }, 200);

                menuDelay = setTimeout(function() {
                    $('#header').addClass('mouseover');
                }, 200);
            },
            mouseleave: function() {
                if (window.is_touch_device() && window.detectIE()) return;

                var that = this;

                clearTimeout(menuDelay);
                clearTimeout(menuDelay2);

                menuDelay2 = setTimeout(function() {
                    $('ul#menu-main-menu .is-open').removeClass('is-open');
                }, 200);

                setTimeout(function() {
                    $('#header').removeClass('mouseout');
                }, 200);

                $('#header').removeClass('mouseover');
            }
        },
        'ul#menu-main-menu'
    );

    function checkOverflowingSubs() {
        $('[data-depth="2"][data-out="mobile-menu-sub"]').each(function() {
            var prevElem = false;

            $(' > .sub-menu > .menu-show-more-btn', this).css(
                'display',
                'none'
            );

            $(
                ' > .sub-menu > li:not([data-on-mobile-only],[data-out="mobile-menu-breadcrumb"],.menu-show-more-btn)',
                this
            ).each(function() {
                if (this.offsetLeft >= this.parentElement.offsetWidth) {
                    if (prevElem)
                        $('.menu-show-more-btn', this.parentElement)
                            .css('display', 'block')
                            .insertBefore(prevElem);

                    return false;
                }

                prevElem = this;
            });
        });
    }

    var timeout = false;

    $(window).resize(function() {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(function() {
            checkOverflowingSubs();
        }, 500);
    });

    checkOverflowingSubs();
    setTimeout(function() {
        checkOverflowingSubs();
    }, 500);
    $(document).ready(function() {
        checkOverflowingSubs();
    });

    // POPUP (in footer.php)

    $(document).ready(function() {
        var $popup = $('[data-popup]');

        if ($popup.length == 1) {
            var id = $popup.attr('data-id');

            try {
                var _localStorage = localStorage;
            } catch (err) {
                var _localStorage = false;
            }

            $('[data-click="popup-toggle"]', $popup).click(function() {
                if ($popup.is('[data-visible]')) {
                    $popup.removeAttr('data-visible');

                    if (_localStorage)
                        _localStorage.setItem('popup-closed-' + id, Date.now());
                } else {
                    $popup.attr('data-visible', '');
                }
            });

            setTimeout(function() {
                var lastClose_s =
                    (Date.now() -
                        (parseInt(_localStorage.getItem('popup-closed-' + id)) >
                        0
                            ? parseInt(
                                  _localStorage.getItem('popup-closed-' + id)
                              )
                            : 0)) /
                    1000;

                if (lastClose_s > parseInt($popup.attr('data-min-gap'))) {
                    $('[data-popup]').attr('data-visible', '');
                }
            }, parseInt($popup.attr('data-timeout')));
        }
    });
})(jQuery);
